.marksheet-box {
    background-color: #fff;
    /* border: 1px solid #000; */
    padding: 15px;
    margin-top: 20px;
    max-width: 595px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    min-height: 800px;
}


.uni-logo,
.uni-add,
.txt-cntr {
    text-align: center;
}

.uni-add p {
    width: 100px;
    margin: 10px auto;
}

.Studen_Detail {
    margin-top: 20px;
}

.Studen_Detail p {
    margin-bottom: 0px;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.32);
}

.personalDetails p {
    margin: 1px 0px;

}

.personalDetails p strong {
    text-transform: uppercase;
}

.personalDetails p:nth-child(1) {
    color: "red" !important
}

.cus-studetails-tbl tbody tr th {
    font-size: 10px;
    background: white;
    padding: 0;
}

.cus-studetails-tbl tbody tr td {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: bold;
    background: white;
    padding: 0;

}

.center-details {
    position: relative;
}

.qrlogo img {
    position: absolute;
    bottom: 2%;
    right: 3%;
    width: 100px;
    height: 100px;
}

/* 
.generate-qr svg {
    width: 80px;
    height: 80px;
} */


.cirtifiacte-cus img {
    width: 70px;
    height: 100px;
}

.city-title-box {
    position: relative;
    top: 60px;
}

.marksheetlogo img {

    width: 90px;
    height: 90px;
}

.nfssc-name {
    color: red;
    font-size: 22px;
    font-weight: bold;
}

.full-name {
    color: red;
    font-size: 20px;
    font-weight: bold;
    word-spacing: 1.4px;
}

.center-name {
    font-size: 11px;
    font-weight: 600;
}

.reg-no {
    font-size: 9px;
    font-weight: 600;
}

.marks-details {
    display: inline-block;

}

.marks-details h6 {
    font-size: 9px;
    padding: 10px 6px;
    background-color: red;
    color: #fff;
    margin-bottom: 0;
    line-height: 0;
    border-radius: 3px;
}


.issueData {
    font-size: 10px;
    font-weight: bold;
}

.issueData img {
    width: 200px;
    height: 30px;
}

.center-state {
    text-transform: uppercase;
}

* {
    border-color: #00000075 !important;
}

.table-div thead th {
    border-bottom: 0px !important;
}

.Studen_Detail strong {
    font-size: 10px !important;
}

.tablefirst thead tr th {
    font-size: 10px !important;
    padding: 0px 3px;
    background: white;
}

.tablefirst tbody tr th {
    font-size: 10px !important;
    padding: 0px 3px;
    background: white;
}

.tablefirst tbody tr td {
    font-size: 10px !important;
    padding: 0px 3px;
    background: white;
}

.tablesecond thead tr th {
    font-size: 10px !important;
    background: white;
    /* padding: 4px 1px; */
}

.tablesecond tbody tr th {
    font-size: 10px !important;
    padding: 0px 3px;
    background: white;
}

.tablesecond tbody tr td {
    font-size: 10px !important;
    padding: 0px 3px;
    background: white;
}

.txt-cntr p strong {
    font-size: 10px !important;
}

.qr-cus {
    position: absolute;
    right: 12px;
    top: 17%;
}


.fotter-box {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.inwords {
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 16px;
}

.inwords span {
    margin: 0 10px;
    text-transform: uppercase;
    font-size: 10.5px;
}

.main-caption-box {
    position: relative;
    top: 225px;
}

.main-caption-boxx {
    position: relative;
    top: 175px;
}



/* certificate style  */
.cirtificate-container {
    text-align: center;
    margin-top: 30px;
}

.cirtificate-container h6 {
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 10px;

}

.cirtificate-container h5 {
    font-size: 14px;
    font-weight: bold;
    margin: 15px 0px;
    font-style: italic;
    font-family: ui-monospace;

}

.crt-footer {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    padding: 5px 0px;
    white-space: nowrap;
}

.cirtificate-cus img {
    width: 70px;
    height: 100px;
    border: 2px solid black;
}

.cirtificate-cus {
    position: absolute;
    right: 12px;
    top: 20%;
}

.form-button-styles {
    display: inline-block;
    padding: 2px 13px;
    border-radius: 6px;
    border: 0;
    outline: 0;
    color: var(--light);
    font-size: 12px;
    background: var(--primary);
    transition: 0.5s all ease;
}

.form-button-styles:hover {
    background: var(--secondary);
}

.BoxD table tbody tr td b {
    color: red;
}

/* new  cirtificate */
@page {
    size: A4;
    margin: 0;
}

@media print {
    .modal-dialog {
        width: 100%;
        max-width: 210mm;
        margin: 0 auto;

    }

    .modal-content {
        height: 100%;
        border: initial;
        border-radius: initial;
        box-shadow: initial;
        background: initial;
    }

    .hide-on-print {
        display: none !important;
    }
}