.txt-center {
    text-align: center;
}

.border- {
    border: 1px solid #000 !important;
}

.padding {
    padding: 15px;
}

.mar-bot {
    margin-bottom: 15px;
}

.admit-card {
    border: 2px solid #000;
    padding: 15px;
    margin: 20px 0;
}

.BoxA {
    border: 1px solid black;
    background-color: #f7eeee;
}

.BoxA h5,
.BoxA p {
    margin: 0;
}

h5 {
    text-transform: uppercase;
}

table img {
    width: 100%;
    margin: 0 auto;
}

.table-bordered td,
.table-bordered th,
.table thead th {
    border: 1px solid #000000 !important;

}

.hall-imgsection img {
    width: 80px;
    height: 100px;
}

.hall-datasection {
    text-align: center;
    padding: 8px 0px;
}

.hall-datasection h5 {
    color: #ff0000cf;
    font-weight: bold;
    font-size: 30px;
}

.hall-datasection p {
    color: #ff0000cf;
    font-weight: bold;
    font-size: 22px;
    padding: 4px 0px;
}

.hall-ticket {
    font-size: 18px;
    font-weight: bold;
    text-decoration: underline;
}

.instruction p {
    font-size: 12px;
}

.instruction h6 {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    padding: 10px 0px;
}

/* new  cirtificate */
@page {
    size: A4;
    margin: 0;
}

@media print {
    .modal-dialog {
        width: 100%;
        max-width: 210mm;
        margin: 0 auto;

    }

    .modal-content {
        height: 100%;
        border: initial;
        border-radius: initial;
        box-shadow: initial;
        background: initial;
    }

    /* .hide-on-print {
        display: none !important;
    } */
}

@media print {
    body * {
        visibility: hidden;
    }

    .modal-body,
    .modal-body * {
        visibility: visible;
    }

    .modal-body {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        margin: 0;
        padding: 0;
        background-color: #fff;
        /* Set the background color you want for printing */
    }
}

@media print {
    body {
        margin: 0;
        padding: 0;
        font-family: Arial, sans-serif;
        font-size: 12px;
    }

    .container {
        width: 100%;
        max-width: 800px;
        /* Adjust as needed for A4 size */
        margin: 0 auto;
    }

    .admit-card {
        border: 1px solid #ccc;
        padding: 10px;
        margin-bottom: 20px;
    }

    .BoxA,
    .BoxD,
    .BoxF {
        border: 1px solid #ccc;
        padding: 10px;
        margin-bottom: 10px;
    }

    .txt-center {
        text-align: center;
    }

    .table {
        width: 100%;
        border-collapse: collapse;
    }

    .table-bordered th,
    .table-bordered td {
        border: 1px solid #000;
        padding: 5px;
    }

    .mar-bot {
        margin-bottom: 10px;
    }

    .border- {
        border: 1px solid #000;
    }

    .padding {
        padding: 10px;
    }

    .instruction {
        margin-top: 20px;
    }

    /* You can add more styles as needed */
}